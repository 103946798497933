import React from "react";
import { IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "black",
  },
});

export const firestoreKeys = Object.freeze({
  USERS: "users",
  COMPANIES: "companies",
  COMPANY_USERS: "companyUsers",
  INTERVIEWS: "interviews",
  SHORTLIST: "shortlist",
  PURCHASES: "purchases",
  VACANCIES: "vacancies",
  RESPONSES: "responses",
  NOTIFICATIONS: "notifications",
  MAIL: "mail",
});

export const toastSeverity = Object.freeze({
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
});

export const firebaseErrorCodes = Object.freeze({
  EMAIL_IN_USER: "auth/email-already-in-use",
});

export const CITY_OPTIONS = ["All", "Cape Town", "Stellenbosch"];

export const SEARCH_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "employer", label: "Current/Last Employer", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "videoLoaded", label: "Video Loaded", align: "left" },
  { id: "cvLoaded", label: "CV Loaded", align: "left" },
];

export const TEMPORARY_URL_COLUMNS = [
  { id: "email", label: "Client Email", align: "left" },
  { id: "user", label: "User", align: "left" },
  { id: "role", label: "Role", align: "left" },
  { id: "createdAt", label: "Date Of Invite", align: "left" },
  { id: "disable", label: "Disable", align: "left" },
];

export const INTERVIEW_RESPONSE_COLUMNS = [
  { id: "shorlist", label: "Shortlist", align: "left" },
  { id: "profilePicture", label: "Profile Picture", align: "left" },
  { id: "name", label: "Name", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "dateInvited", label: "Date of Invite", align: "left" },
  { id: "dueDate", label: "Due Date", align: "left" },
  {
    id: "status",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Status:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              The Status Indicators: <br />
              <br />
              <span style={{ color: "yellow" }}>Pending</span> = Waiting your
              review and response handling.
              <br />
              <br />
              <span style={{ color: "green" }}>Approved</span> = Interview
              viewed and response marked as approved.
              <br />
              <br />
              <span style={{ color: "red" }}>Declined</span> = Interview viewed
              and response marked as declined.
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
];

export const ADMIN_INTERVIEW_RESPONSE_COLUMNS = [
  { id: "shorlist", label: "Shortlist", align: "left" },
  { id: "profilePicture", label: "Profile Picture", align: "left" },
  { id: "name", label: "Name", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "dateInvited", label: "Date of Invite", align: "left" },
  { id: "dueDate", label: "Due Date", align: "left" },
  {
    id: "status",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Status:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              The Status Indicators: <br />
              <br />
              <span style={{ color: "yellow" }}>Pending</span> = Waiting your
              review and response handling.
              <br />
              <br />
              <span style={{ color: "green" }}>Approved</span> = Interview
              viewed and response marked as approved.
              <br />
              <br />
              <span style={{ color: "red" }}>Declined</span> = Interview viewed
              and response marked as declined.
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
  { id: "share", label: "Share", align: "left" },
];

export const SHORTLIST_COLUMNS = [
  { id: "name", label: "Name", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "location", label: "Location", align: "left" },
];

export const ODI_SHORTLIST_COLUMNS = [
  { id: "invite", label: "Invite", align: "left" },
  { id: "name", label: "Name", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "location", label: "Location", align: "left" },
  {
    id: "status",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Status:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              The Status Indicators: <br />
              <br />
              <span style={{ color: "#7635dc" }}>Invite</span> = Candidate has
              not received an invitation to this interview. <br />
              <br />
              <span style={{ color: "orange" }}>Invited</span> = Candidate was
              invited to this interview. <br />
              <br />
              <span style={{ color: "green" }}>Submitted</span> = Candidate
              received the questions and submitted the answers. <br />
              <br />
              <span style={{ color: "red" }}>Declined</span> = Candidate
              received the invite and declined to attend. <br />
              <br />
              Should the status not change to accepted before due date, feel
              free to reach out and send a reminder to the candidate.
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
];

export const ADMIN_INVITED_USERS_COLUMNS = [
  {
    id: "shorlist",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Shorlist:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              Users on to the next round
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
  { id: "name", label: "Name", align: "left" },
  { id: "emailAddress", label: "Email Address", align: "left" },
  { id: "dateInvited", label: "Date of Invite", align: "left" },
  { id: "dueDate", label: "Due Date", align: "left" },
  {
    id: "status",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Status:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              The Status Indicators: <br />
              <br />
              <span style={{ color: "#7635dc" }}>Submitting</span> = Shows that
              you have sent an invite to the candidate and waiting for the
              interview feedback.
              <br />
              <br />
              <span style={{ color: "yellow" }}>Pending</span> = Shows that the
              candidate responded and is waiting your review and response
              handling.
              <br />
              <br />
              <span style={{ color: "green" }}>Approved</span> = Shows that you
              have approved the interview response with potential feedback in
              comment section.
              <br />
              <br />
              <span style={{ color: "red" }}>Declined</span> = Shows that you
              have declined the interview response with potential feedback in
              comment section.
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
  { id: "share", label: "Share", align: "left" },
];

export const INVITED_USERS_COLUMNS = [
  {
    id: "shorlist",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Shorlist:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              Users on to the next round
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
  { id: "name", label: "Name", align: "left" },
  { id: "emailAddress", label: "Email Address", align: "left" },
  { id: "dateInvited", label: "Date of Invite", align: "left" },
  { id: "dueDate", label: "Due Date", align: "left" },
  {
    id: "status",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px" }}>Status:</span>
        <CustomWidthTooltip
          style={{ maxWidth: "300px", backgroundColor: "black" }}
          placement="right-end"
          title={
            <div>
              The Status Indicators: <br />
              <br />
              <span style={{ color: "#7635dc" }}>New</span> = Shows that you
              have sent an invite to the candidate and waiting for the interview
              feedback.
              <br />
              <br />
              <span style={{ color: "yellow" }}>Pending</span> = Shows that the
              candidate responded and is waiting your review and response
              handling.
              <br />
              <br />
              <span style={{ color: "green" }}>Approved</span> = Shows that you
              have approved the interview response with potential feedback in
              comment section.
              <br />
              <br />
              <span style={{ color: "red" }}>Declined</span> = Shows that you
              have declined the interview response with potential feedback in
              comment section.
              <br />
            </div>
          }
        >
          <IconButton style={{ color: "#ffffff" }}>
            <InfoOutlinedIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </CustomWidthTooltip>
      </div>
    ),
    align: "left",
  },
];

export const VACANCIES_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "salary", label: "Salary", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "datePosted", label: "Date Posted", align: "left" },
  { id: "expiryDate", label: "Expiry Date", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "numberOfResponses", label: "Number Of Responses", align: "left" },
];

export const JOB_BOARD_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "salary", label: "Salary", align: "left" },
  { id: "rateType", label: "Rate Type", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "datePosted", label: "Date Posted", align: "left" },
  { id: "expiryDate", label: "Expiry Date", align: "left" },
];

export const LANDING_PAGE_VACANCIES_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "salary", label: "Salary", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "datePosted", label: "Date Posted", align: "left" },
  { id: "expiryDate", label: "Expiry Date", align: "left" },
];

export const VACANCY_RESPONSE_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "employer", label: "Current/Last Employer", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "videoLoaded", label: "Video Loaded", align: "left" },
  { id: "cvLoaded", label: "CV Loaded", align: "left" },
  { id: "rating", label: "Rating", align: "left" },
];

export const INTERVIEW_COLUMNS = [
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "salary", label: "Salary", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "postedBy", label: "Posted By", align: "left" },
  { id: "datePosted", label: "Date Posted", align: "left" },
  { id: "dueDate", label: "Due Date", align: "left" },
];

export const COMPANY_USER_LIST_COLUMNS = [
  { id: "name", label: "Name", align: "left" },
  { id: "type", label: "Type", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "joinedDate", label: "Joined Date", align: "left" },
  { id: "lastActiveDate", label: "Last Active Date", align: "left" },
  { id: "removeUser", label: "Remove User", align: "left" },
];

export const PURCHASE_LIST_COLUMNS = [
  { id: "name", label: "Name", align: "left" },
  { id: "jobTitle", label: "Job Title", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "datePurchased", label: "Date Purchase", align: "left" },
  { id: "expiryDate", label: "Expiry Date", align: "left" },
  { id: "purchasedBy", label: "Purchased By", align: "left" },
];

export const COMPANY_LIST_COLUMNS = [
  { id: "name", label: "Name", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "numberOfUsers", label: "Number Of Users", align: "left" },
  { id: "status", label: "Status", align: "left" },
];

export const COUNTRY_OPTIONS = [
  "All",
  "South Africa",
  "United Kingdom",
  "United States",
];

export const PROVINCE_OPTIONS = [
  "All",
  "Eastern Cape",
  "Free State",
  "Gauteng",
  "KwaZulu-Natal",
  "Limpopo",
  "Mpumalanga",
  "Norther Cape",
  "North West",
  "Western Cape",
];

export const RACE_OPTIONS = ["All", "Black", "Caucasian", "Asian", "Coloured"];

export const GENDER_OPTIONS = ["All", "Male", "Female", "Other"];

export const EMPLOYMENT_STATUS_OPTIONS = [
  "All",
  "Employed",
  "Unemployed",
  "Self Employed",
];

export const LANGUAGE_OPTIONS = [
  "All",
  "English",
  "IsiZulu",
  "IsiXhosa",
  "Afrikaans",
  "Sesotho",
  "Setswana",
  "Xitsonga",
  "siSwati Tshivenda",
  "IsiNdebele",
];

export const DRIVERS_LICENSE_OPTIONS = ["All", "Yes", "No"];

export const AGE_GROUP_OPTIONS = [
  "All",
  "18-25",
  "26-35",
  "36-45",
  "46-55",
  "56-65",
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  "Full time",
  "Part time",
  "Permanent",
  "Temporary",
  "Contract",
  "Learnership",
  "Graduate",
  "Internship",
  "Other",
];

export const CURRENCIES = {
  AED: "United Arab Emirates Dirham",
  AFN: "Afghan Afghani",
  ALL: "Albanian Lek",
  AMD: "Armenian Dram",
  ANG: "Netherlands Antillean Guilder",
  AOA: "Angolan Kwanza",
  ARS: "Argentine Peso",
  AUD: "Australian Dollar",
  AWG: "Aruban Florin",
  AZN: "Azerbaijani Manat",
  BAM: "Bosnia-Herzegovina Convertible Mark",
  BBD: "Barbadian Dollar",
  BDT: "Bangladeshi Taka",
  BGN: "Bulgarian Lev",
  BHD: "Bahraini Dinar",
  BIF: "Burundian Franc",
  BMD: "Bermudan Dollar",
  BND: "Brunei Dollar",
  BOB: "Bolivian Boliviano",
  BRL: "Brazilian Real",
  BSD: "Bahamian Dollar",
  BTC: "Bitcoin",
  BTN: "Bhutanese Ngultrum",
  BWP: "Botswanan Pula",
  BYN: "Belarusian Ruble",
  BZD: "Belize Dollar",
  CAD: "Canadian Dollar",
  CDF: "Congolese Franc",
  CHF: "Swiss Franc",
  CLF: "Chilean Unit of Account (UF)",
  CLP: "Chilean Peso",
  CNH: "Chinese Yuan (Offshore)",
  CNY: "Chinese Yuan",
  COP: "Colombian Peso",
  CRC: "Costa Rican Colón",
  CUC: "Cuban Convertible Peso",
  CUP: "Cuban Peso",
  CVE: "Cape Verdean Escudo",
  CZK: "Czech Republic Koruna",
  DJF: "Djiboutian Franc",
  DKK: "Danish Krone",
  DOP: "Dominican Peso",
  DZD: "Algerian Dinar",
  EGP: "Egyptian Pound",
  ERN: "Eritrean Nakfa",
  ETB: "Ethiopian Birr",
  EUR: "Euro",
  FJD: "Fijian Dollar",
  FKP: "Falkland Islands Pound",
  GBP: "British Pound Sterling",
  GEL: "Georgian Lari",
  GGP: "Guernsey Pound",
  GHS: "Ghanaian Cedi",
  GIP: "Gibraltar Pound",
  GMD: "Gambian Dalasi",
  GNF: "Guinean Franc",
  GTQ: "Guatemalan Quetzal",
  GYD: "Guyanaese Dollar",
  HKD: "Hong Kong Dollar",
  HNL: "Honduran Lempira",
  HRK: "Croatian Kuna",
  HTG: "Haitian Gourde",
  HUF: "Hungarian Forint",
  IDR: "Indonesian Rupiah",
  ILS: "Israeli New Sheqel",
  IMP: "Manx pound",
  INR: "Indian Rupee",
  IQD: "Iraqi Dinar",
  IRR: "Iranian Rial",
  ISK: "Icelandic Króna",
  JEP: "Jersey Pound",
  JMD: "Jamaican Dollar",
  JOD: "Jordanian Dinar",
  JPY: "Japanese Yen",
  KES: "Kenyan Shilling",
  KGS: "Kyrgystani Som",
  KHR: "Cambodian Riel",
  KMF: "Comorian Franc",
  KPW: "North Korean Won",
  KRW: "South Korean Won",
  KWD: "Kuwaiti Dinar",
  KYD: "Cayman Islands Dollar",
  KZT: "Kazakhstani Tenge",
  LAK: "Laotian Kip",
  LBP: "Lebanese Pound",
  LKR: "Sri Lankan Rupee",
  LRD: "Liberian Dollar",
  LSL: "Lesotho Loti",
  LYD: "Libyan Dinar",
  MAD: "Moroccan Dirham",
  MDL: "Moldovan Leu",
  MGA: "Malagasy Ariary",
  MKD: "Macedonian Denar",
  MMK: "Myanma Kyat",
  MNT: "Mongolian Tugrik",
  MOP: "Macanese Pataca",
  MRU: "Mauritanian Ouguiya",
  MUR: "Mauritian Rupee",
  MVR: "Maldivian Rufiyaa",
  MWK: "Malawian Kwacha",
  MXN: "Mexican Peso",
  MYR: "Malaysian Ringgit",
  MZN: "Mozambican Metical",
  NAD: "Namibian Dollar",
  NGN: "Nigerian Naira",
  NIO: "Nicaraguan Córdoba",
  NOK: "Norwegian Krone",
  NPR: "Nepalese Rupee",
  NZD: "New Zealand Dollar",
  OMR: "Omani Rial",
  PAB: "Panamanian Balboa",
  PEN: "Peruvian Nuevo Sol",
  PGK: "Papua New Guinean Kina",
  PHP: "Philippine Peso",
  PKR: "Pakistani Rupee",
  PLN: "Polish Zloty",
  PYG: "Paraguayan Guarani",
  QAR: "Qatari Rial",
  RON: "Romanian Leu",
  RSD: "Serbian Dinar",
  RUB: "Russian Ruble",
  RWF: "Rwandan Franc",
  SAR: "Saudi Riyal",
  SBD: "Solomon Islands Dollar",
  SCR: "Seychellois Rupee",
  SDG: "Sudanese Pound",
  SEK: "Swedish Krona",
  SGD: "Singapore Dollar",
  SHP: "Saint Helena Pound",
  SLL: "Sierra Leonean Leone",
  SOS: "Somali Shilling",
  SRD: "Surinamese Dollar",
  SSP: "South Sudanese Pound",
  STD: "São Tomé and Príncipe Dobra (pre-2018)",
  STN: "São Tomé and Príncipe Dobra",
  SVC: "Salvadoran Colón",
  SYP: "Syrian Pound",
  SZL: "Swazi Lilangeni",
  THB: "Thai Baht",
  TJS: "Tajikistani Somoni",
  TMT: "Turkmenistani Manat",
  TND: "Tunisian Dinar",
  TOP: "Tongan Pa'anga",
  TRY: "Turkish Lira",
  TTD: "Trinidad and Tobago Dollar",
  TWD: "New Taiwan Dollar",
  TZS: "Tanzanian Shilling",
  UAH: "Ukrainian Hryvnia",
  UGX: "Ugandan Shilling",
  USD: "United States Dollar",
  UYU: "Uruguayan Peso",
  UZS: "Uzbekistan Som",
  VEF: "Venezuelan Bolívar Fuerte (Old)",
  VES: "Venezuelan Bolívar Soberano",
  VND: "Vietnamese Dong",
  VUV: "Vanuatu Vatu",
  WST: "Samoan Tala",
  XAF: "CFA Franc BEAC",
  XAG: "Silver Ounce",
  XAU: "Gold Ounce",
  XCD: "East Caribbean Dollar",
  XDR: "Special Drawing Rights",
  XOF: "CFA Franc BCEAO",
  XPD: "Palladium Ounce",
  XPF: "CFP Franc",
  XPT: "Platinum Ounce",
  YER: "Yemeni Rial",
  ZAR: "South African Rand",
  ZMW: "Zambian Kwacha",
  ZWL: "Zimbabwean Dollar",
};

export const WORKING_SCENARIO_OPTIONS = ["Onsite", "Hybrid", "Remote"];

export const BUSINESS_TYPE_OPTIONS = [
  "Sole Proprietorship",
  "Private Company",
  "Public Company",
  "Non-profit Company",
  "State-owned Company",
];

export const INDUSTRY_OPTIONS = [
  "All",
  "Advertising",
  "Cummunication & PR",
  "Agriculture",
  "Automotive Sector",
  "Banking",
  "Call Centre Industry",
  "Chemical, Petrochemical, Oil & Gas",
  "Consulting",
  "Distribution, Transport & Logistics",
  "Education & Training",
  "Electricity, Energy & Water Supply",
  "Engineering",
  "Finance Services",
  "FMCG",
  "Freight, Import & Export",
  "Government",
  "HR Services, Recruitment & Selection",
  "Insurance",
  "IT & Internet",
  "Legal, Risk & Compliance",
  "Manufacturing",
  "Media & Publishing",
  "Mining Industry",
  "Office Automation",
  "Public Services",
  "Steel Industry",
  "Supply Chain Management",
  "Telecommunications",
  "Wholesale & Retail Trade",
];

export const JOB_TITLE_OPTIONS = [
  "All",
  "Accountant",
  "Account Manager",
  "Account Manager: IT",
  "Application Specialist",
  "BI Analyst",
  "Bookkeeper",
  "Brand Manager",
  "Business Development Manager",
  "Business Development Manager: IT",
  "Business Development Rep",
  "Buyer",
  "Cashbook Clerk",
  "Chartered Accountant",
  "Compliance Manager",
  "Compliance Officer",
  "Credit Controller",
  "Credit Manager",
  "Creditors Clerk",
  "Data Analyst",
  "Data Engineer",
  "Debtors Clerk",
  "Design Engineer",
  "Developer",
  "Dispatch Clerk",
  "Driver",
  "Export & Import Manager",
  "Finance Analyst",
  "Finance Clerk",
  "Finance Manager",
  "Financial Administrator",
  "Financial Advicer",
  "Financial Controller",
  "Financial Intern",
  "Front End Developer",
  "Governence, Risk & Compliance Manager",
  "Governence, Risk & Compliance Officer",
  "HR Admin Clerk",
  "HR Assistant",
  "HR Coordinator",
  "HR Intern",
  "HR Manager",
  "HR Officer",
  "HR Supervisor",
  "HR Trainee",
  "Integration Manager",
  "Internal Auditor",
  "IR Manager",
  "IT Architect",
  "IT Compliance Analyst",
  "IT Engineer",
  "IT Intern",
  "IT Manager",
  "IT Support Technician",
  "IT Technician",
  "Java Developer",
  "Key Accounts Manager",
  "Legal and Compliance Manager",
  "Legal and Compliance Officer",
  "Logistics Administrator",
  "Logistics Coordinator",
  "Logistics Manager",
  "Machine Learning Engineer",
  "Marketing Administrator",
  "Marketing Agent",
  "Marketing Analyst",
  "Marketing Assistant",
  "Marketing Manager",
  "Marketing Rep",
  "Payroll Clerk",
  "Payroll Manager",
  "Payroll Supervisor",
  "Platform Engineer",
  "Portfolio Manager: IT",
  "Procurement Administrator",
  "Procurement Manager",
  "Procurement Supervisor",
  "Programmer",
  "Project Manager: IT",
  "Recruitment Consultant",
  "Recruitment Manager",
  "Relationship Executive",
  "Relationship Manager",
  "Risk Administrator",
  "Risk and Compliance Manager",
  "Risk Manager",
  "Risk Officer",
  "Sales Administrator",
  "Sales Consultant",
  "Sales Coordinator",
  "Sales Executive",
  "Sales Manager",
  "Sales Representative",
  "Sales Supervisor",
  "Software Engineer",
  "Stock Controller",
  "Storeman",
  "Supply Chain Administrator",
  "Supply Chain Analyst",
  "Supply Chain Assistant",
  "Supply Chain Intern",
  "Supply Chain Manager",
  "Supply Chain Supervisor",
  "Systems Administrator",
  "Systems Analyst",
  "Talent Acquisition Manager",
  "Talent Acquisition Specialist",
  "Tax Specialist",
  "Technical Manager",
  "Trainee Chartered Accountant",
  "Training & Development Manager",
  "Warehouse Manager",
  "Warehouse Supervisor",
];
