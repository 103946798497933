// routes
import Router from "../shared/routes";
import React, { useState, useEffect } from "react";
// theme
import ThemeProvider from "../theme";
// components
import ThemeSettings from "../shared/components/settings";
import { ChartStyle } from "../shared/components/chart";
import {
  ScrollToTop,
  NotistackProvider,
  ProgressBarStyle,
  Toast,
} from "../shared/components/base";
import MotionLazyContainer from "../shared/components/animate/MotionLazyContainer";
import "../shared/styles/global.css";
import { DataProvider } from "./DataContext";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <DataProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
              <Toast />
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </DataProvider>
  );
}
