import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
// Import the updated PATH_VACANCY
import { PATH_VACANCY_USER, PATH_SEARCH,PATH_VACANCY } from 'src/shared/routes/paths';

// @mui
import { Box, Link, Typography, Breadcrumbs as MUIBreadcrumbs, Button } from '@mui/material';

// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  activeLast: PropTypes.bool,
  links: PropTypes.array.isRequired,
};

export default function Breadcrumbs({ links, activeLast = false, ...other }) {
  const navigate = useNavigate();

  function processQueryParameters() {
    const url = window.location.href;
    const baseUrl = window.location.origin + '/#';
    
    if (url.includes("/#/vacancies/") && !url.includes("list")) {
      const newUrl = baseUrl + PATH_VACANCY.list;
      updateUrl();
      return;
    }
  
    const userId = extractUserId(url);
    const vacancyParam = extractVacancyParam(url);
  
    if (vacancyParam) {
      try {
        const vacancyObject = parseVacancyParam(vacancyParam);
        const vacancyPath = PATH_VACANCY_USER.profile(vacancyObject.id, userId);
        navigate(vacancyPath);
      } catch (error) {
        console.error('Error processing vacancy query parameter:', error);
      }
    } else if (url.includes('?search=true')) {
      const listPath = PATH_SEARCH.list + '?userId=' + userId;
      navigate(listPath);
    } else {
      window.history.go(-1);
    }
  }
  
  function extractUserId(url) {
    const userIdStartIndex = url.indexOf('/user/') + 6;
    const userIdEndIndex = url.indexOf('/profile');
    return (userIdStartIndex !== -1 && userIdEndIndex !== -1) ? url.substring(userIdStartIndex, userIdEndIndex) : "";
  }
  
  function extractVacancyParam(url) {
    const vacancyIndex = url.indexOf('vacancy=');
    if (vacancyIndex !== -1) {
      let vacancyParam = url.substring(vacancyIndex + 'vacancy='.length);
      const ampersandIndex = vacancyParam.indexOf('&');
      if (ampersandIndex !== -1) {
        vacancyParam = vacancyParam.substring(0, ampersandIndex);
      }
      return vacancyParam;
    }
    return null;
  }
  
  function parseVacancyParam(vacancyParam) {
    const decodedVacancy = decodeURIComponent(vacancyParam);
    return JSON.parse(decodedVacancy);
  }
  
  function updateUrl() {
    navigate(PATH_VACANCY.list);
  }
  
  

  const currentLink = links[links.length - 1].name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);

  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={<Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />}
      {...other}
    >
      <Button variant="text" onClick={processQueryParameters}>
        Back
      </Button>
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

LinkItem.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string,
  }),
};

function LinkItem({ link }) {
  const { href, name, icon } = link;
  return (
    <Link
      key={name}
      variant="body2"
      component={RouterLink}
      to={href || '#'}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' },
      }}
    >
      {icon && <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>}
      {name}
    </Link>
  );
}
