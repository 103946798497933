import firebase from 'firebase';


// const config = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID
// };

// DEV
const devConfig = {
  apiKey: 'AIzaSyBEOYHRngEeVCp2WII3KblwqlDrm9_6J28',
  authDomain: 'pik-uniq-dev.firebaseapp.com',
  projectId: 'pik-uniq-dev',
  storageBucket: 'pik-uniq-dev.appspot.com',
  messagingSenderId: '569632201467',
  appId: '1:569632201467:web:0853e03d207b3445046e5e',
};

// QA
const qaConfig = {
  apiKey: 'AIzaSyB3cR9AE1_0PWFtSKO-arMfmoYHl6JrvNs',
  authDomain: 'pik-uniq-qa.firebaseapp.com',
  projectId: 'pik-uniq-qa',
  storageBucket: 'pik-uniq-qa.appspot.com',
  messagingSenderId: '43436510245',
  appId: '1:43436510245:web:35965c23376e4893d643cc',
};

// PROD
const prodConfig = {
  apiKey: 'AIzaSyBGC_NmycehZM8ljFOTSYOtg5YSkrz0E2M',
  authDomain: 'pik-uniq-prod.firebaseapp.com',
  projectId: 'pik-uniq-prod',
  storageBucket: 'pik-uniq-prod.appspot.com',
  messagingSenderId: '515228544905',
  appId: '1:515228544905:web:80c49291458e12b8c53b58',
};

firebase.initializeApp(prodConfig);

export const { auth } = firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();

