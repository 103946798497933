function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_USER = "/user";
const ROOTS_COMPANY = "/company";
const ROOTS_COMPANY_USER = "/company-user";
const ROOTS_ACCOUNT_DETAILS = "/account-details";
const ROOTS_SEARCH = "/search";
const ROOTS_SHORTLIST = "/shortlist";
const ROOTS_PURCHASES = "/purchases";
const ROOTS_VACANIES = "/vacancies";
const ROOTS_JOB_BOARD = "/jobs";
const ROOTS_PREMIUM_VACANIES = "/jobs";
const ROOTS_INTERVIEWS = "/interviews";
const ROOTS_TEMPORARY_ACCESS = "/temporary-access";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
};

export const PATH_USER = {
  root: ROOTS_USER,
  profile: (id) => path(ROOTS_USER, `/${id}/profile`),
};

export const PATH_COMPANY = {
  list: path(ROOTS_COMPANY, "/list"),
  profile: (id) => path(ROOTS_COMPANY, `/${id}/profile`),
};

export const PATH_COMPANY_USER = {
  list: path(ROOTS_COMPANY_USER, "/list"),
  profile: (id) => path(ROOTS_COMPANY_USER, `/${id}/profile`),
};

export const PATH_SHORTLIST = {
  list: path(ROOTS_SHORTLIST, "/list"),
};

export const PATH_SEARCH = {
  list: path(ROOTS_SEARCH, "/list"),
};

export const PATH_PURCHASES = {
  list: path(ROOTS_PURCHASES, "/list"),
};

export const PATH_ACCOUNT_DETAILS = {
  profile: path(ROOTS_ACCOUNT_DETAILS, "/profile"),
};

export const PATH_VACANCY = {
  list: path(ROOTS_VACANIES, "/list"),
  profile: (id) => path(ROOTS_VACANIES, `/${id}/profile`),
};

export const PATH_PREMIUM_VACANCY = {
  list: path(ROOTS_PREMIUM_VACANIES),
  profile: (id) => path(ROOTS_PREMIUM_VACANIES, `/${id}`),
};

export const PATH_JOB_BOARD = {
  list: path(ROOTS_JOB_BOARD, "/job-board"),
};

export const PATH_VACANCY_USER = {
  list: path(ROOTS_VACANIES, "/list"),
  profile: (id, userId) =>
    path(ROOTS_VACANIES, `/${id}/profile${userId ? `?userId=${userId}` : ""}`),
};

export const TEMPORARY_ACCESS = {
  list: path(ROOTS_TEMPORARY_ACCESS, "/table"),
};

export const PATH_INTERVIEW = {
  list: path(ROOTS_INTERVIEWS, "/list"),
  profile: (id) => path(ROOTS_INTERVIEWS, `/${id}/profile`),
  response: (interviewId, responseId) =>
    path(ROOTS_INTERVIEWS, `/${interviewId}/profile/response/${responseId}`),
};
